<template>
  <div>
    <!-- 新增轮播图按钮 -->
    <div class="c-viewpager-manage_add-buttom-bag">
      <l-button
        type="default"
        @click="addViewpager()"
      >
        添加轮播图
      </l-button>
      <l-button
        type="default"
        @click="addViewpagerTime()"
      >
        轮播时间间隔设置
      </l-button>
    </div>
    <!-- 新增轮播图按钮结束 -->
    <!-- 列表展示区 -->
    <l-table-common
      :data-source="tableData"
      @change="showList"
    >
      <l-table-column
        data-index="index"
        title="序号"
      />
      <l-table-column
        data-index="name"
        title="图片名称"
      />
      <l-table-column title="轮播顺序调整">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            v-if="$utils.isUp(scope.index, tableData)"
            class="c-viewpager-manage_mobile-buttom"
            @click="viewpagerManageSort(scope.id, 1)"
          >
            上移
          </l-button>
          <l-button
            v-if="$utils.isDown(scope.index, tableData)"
            class="c-viewpager-manage_mobile-buttom"
            @click="viewpagerManageSort(scope.id, 2)"
          >
            下移
          </l-button>
        </template>
      </l-table-column>
      <l-table-column title="详情">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            type="link"
            @click="viewpageDetail(scope)"
          >
            <div>
              详情
            </div>
          </l-button>
        </template>
      </l-table-column>
      <l-table-column title="删除">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            type="link"
            @click="viewpageRemove(scope)"
          >
            <div>
              删除
            </div>
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表展示结束区 -->
    <!-- 轮播时间间隔设置弹跳框 -->
    <div>
      <l-modal
        v-model="addbuttomTime"
        title="设置轮播时间"
        @ok="addViewpageTimerYes"
      >
        <div class="c-viewpager-manage_viewpager-time-bag">
          <div class="c-viewpager-manage_viewpager-time-bag-text">每隔 :</div>
          <l-input v-model="standTime" class="c-viewpager-manage_viewpager-time-bag-input" placeholder="请输入" />
          <div class="c-viewpager-manage_viewpager-time-bag-text">S 切换下一张</div>
        </div>
      </l-modal>
    </div>
    <!-- 轮播时间间隔设置 结束区 -->
    <!--新增轮播图 和轮播图详情弹跳框 -->
    <l-modal
      v-model="isShowAddViewpager"
      title="轮播图详情信息"
      :width="600"
      @ok="addViewpagerYes"
      @cancel="viewpagerCancel"
    >
      <div class="p-ai-puzzle_container">
        <div class="p-ai-puzzle_bag">
          <div class="p-ai-puzzle_bag-text">图片名称 :</div>
          <l-input v-model="form.name" class="p-ai-puzzle_bag-input" placeholder="请输入" />
        </div>
        <div class="p-ai-puzzle_bag">
          <div class="p-ai-puzzle_bag-text">上传图片 :</div>
        </div>
        <div v-if="!form.id">
          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">简体中文 :</div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">横屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListAcross"
                :multiple="true"
                @change="handleChange($event,'zhcn','across')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">竖屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListUpright"
                :multiple="true"
                @change="handleChange($event,'zhcn','upright')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">繁体中文 :</div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">横屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListZhtwAcross"
                :multiple="true"
                @change="handleChange($event,'zhtw','across')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">竖屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListZhtwUpright"
                :multiple="true"
                @change="handleChange($event,'zhtw','upright')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">英文 :</div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">横屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListEnAcross"
                :multiple="true"
                @change="handleChange($event,'en','across')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture">
            <div class="p-ai-puzzle_picture-text">竖屏图片:</div>
            <div class="p-ai-puzzle_picture-upload-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                list-type="picture"
                :file-list="fileListEnUpright"
                :multiple="true"
                @change="handleChange($event,'en','upright')"
              >
                <l-button class="p-ai-puzzle_picture-upload"> <a-icon type="upload" />上传</l-button>
              </a-upload>
            </div>
          </div>
        </div>
        <div v-if="form.id">
          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">简体中文 :</div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">横屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlHorZhcn" />
                </template>
                {{ form.resourceNameHorZhcn }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlHorZhcn" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlHorZhcn)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListAcross"
                :multiple="true"
                @change="handleChange($event, 'zhcn','across')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">竖屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlVerZhcn" />
                </template>
                {{ form.resourceNameVerZhcn }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlVerZhcn" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlVerZhcn)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListUpright"
                :multiple="true"
                @change="handleChange($event, 'zhcn','upright')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>

          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">繁体中文 :</div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">横屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlHorZhtw" />
                </template>
                {{ form.resourceNameHorZhtw }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlHorZhtw" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlHorZhtw)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListZhtwAcross"
                :multiple="true"
                @change="handleChange($event, 'zhtw','across')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">竖屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlVerZhtw" />
                </template>
                {{ form.resourceNameVerZhtw }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlVerZhtw" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlVerZhtw)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListZhtwUpright"
                :multiple="true"
                @change="handleChange($event, 'zhtw','upright')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>

          <div class="p-ai-puzzle_bag">
            <div class="p-ai-puzzle_bag-text">英文 :</div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">横屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlHorEn" />
                </template>
                {{ form.resourceNameHorEn }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlHorEn" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlHorEn)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListEnAcross"
                :multiple="true"
                @change="handleChange($event, 'en','across')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>
          <div class="p-ai-puzzle_picture-detail">
            <div class="p-ai-puzzle_text-detail">竖屏图片 :</div>
            <template>
              <a-tooltip class="p-ai-puzzle_picture-name-detail">
                <template slot="title">
                  <img class="p-ai-puzzle_picture-name-img-detail" :src="form.resourceUrlVerEn" />
                </template>
                {{ form.resourceNameVerEn }}
              </a-tooltip>
            </template>
            <l-button v-if="form.resourceUrlVerEn" class="p-ai-puzzle_picture-download-detail" @click="downLoadImg(form.resourceUrlVerEn)">下载</l-button>
            <div class="p-ai-puzzle_upload-detail-outer">
              <a-upload
                :headers="{'session-key': sessionKey}"
                name="file"
                action="/api/admin/instance/ganStand/upload"
                accept="image/*"
                :file-list="fileListEnUpright"
                :multiple="true"
                @change="handleChange($event, 'en','upright')"
              >
                <l-button class="p-ai-puzzle_upload-detail"> <a-icon type="upload" />修改</l-button>
              </a-upload>
            </div>
          </div>
        </div>
      </div>
    </l-modal>
    <!-- 详情 结束区 -->
  </div>
</template>

<script>
export default {
  name: 'ViewpagerManage',
  data() {
    return {
      isShowAddViewpager: false, // 新增主题弹跳框是否显示
      detailDatas: {}, // 详情弹跳窗初始数据缓存区
      isSaveDetailData: true, // 判断详情是否点击保存修改的数据
      addbuttomTime: false, // 轮播图时间设置弹跳框是否显示
      newPage: 1, // 存储当前案例列表页数
      form: { // 新建案例弹窗缓存数据和详情弹跳窗数据缓存区
        id: '', // 储存列表主键
        name: '', // 储存轮播图列表名字
        resourceNameHorEn: '', // 横屏英文资源名称
        resourceNameHorZhcn: '',
        resourceNameHorZhtw: '',
        resourceNameVerEn: '', // 竖屏英文资源名称
        resourceNameVerZhcn: '',
        resourceNameVerZhtw: '',
        resourceUrlHorEn: '', // 横屏英文资源url
        resourceUrlHorZhcn: '',
        resourceUrlHorZhtw: '',
        resourceUrlVerEn: '', // 竖屏英文资源url
        resourceUrlVerZhcn: '',
        resourceUrlVerZhtw: '',
        type: '', // 类型, video、image
      },
      standTime: '', // 轮播图时间间隔
      sessionKey: sessionStorage.getItem('sessionKey'),
      fileListAcross: [], // 新增和修改图片按钮上传路径缓存区
      fileListUpright: [],
      fileListZhtwAcross: [],
      fileListZhtwUpright: [],
      fileListEnAcross: [],
      fileListEnUpright: [],
      file: '', // 文件缓存文件名
      tableData: { // 列表数据存放
        isLoading: false,
        total: 0,
        page: 1,
        size: 8,
        list: [],
      },
    }
  },
  mounted() {
    this.showList(1)
  },
  methods: {
    // 展示列表-------------------
    async showList(page) {
      page = (page && page.current) || page || this.tableData.page || 1
      this.newPage = page // 存储当前案例列表页数
      this.tableData.isLoading = true
      try {
        const data = await this.$store.dispatch('viewpagerManage/viewpagerManageList', {
          page: page,
          size: this.tableData.size
        })
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.list.forEach((item, index) => {
          item.index = index + 1
        })
        this.tableData.total = data.count
        this.tableData.page = page
      } catch (e) {
        this.tableData = this.$options.data().tableData
      }
    },
    // 展示列表结束区-------------

    // 获取上传图片的文件数据
    handleChange(info, type, ststus) {
      // 下面三行连续代码是限制上传图片的数量
      if (type === 'zhcn') {
        if (ststus === 'across') {
          let fileListAcross = [...info.fileList]
          fileListAcross = fileListAcross.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListAcross = fileListAcross
        } else {
          let fileListUpright = [...info.fileList]
          fileListUpright = fileListUpright.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListUpright = fileListUpright
        }

      } else if (type === 'zhtw') {
        if (ststus === 'across') {
          let fileListZhtwAcross = [...info.fileList]
          fileListZhtwAcross = fileListZhtwAcross.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListZhtwAcross = fileListZhtwAcross
        } else {
          let fileListZhtwUpright = [...info.fileList]
          fileListZhtwUpright = fileListZhtwUpright.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListZhtwUpright = fileListZhtwUpright
        }

      } else {
        if (ststus === 'across') {
          let fileListEnAcross = [...info.fileList]
          fileListEnAcross = fileListEnAcross.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListEnAcross = fileListEnAcross
        } else {
          let fileListEnUpright = [...info.fileList]
          fileListEnUpright = fileListEnUpright.slice(-1) // 限制上传图片的数量 -1表示只能最多一张，-2就最多2张
          this.fileListEnUpright = fileListEnUpright
        }

      }

      if (info.file.status === 'done') {
        const data = info.file.response && info.file.response.object
        if (!data) { // 如果上传的代码图片不符合要求，终止图片的上传进数据库，并把本地图片缓存清空
          this.$message.error(info.file.response && info.file.response.message)// 提示报错不符合要求的图片
          if (type === 'zhcn') {
            if (ststus === 'across') {
              this.fileListAcross = [] // 把本地图片缓存清空
            } else {
              this.fileListUpright = []
            }
          } else if (type === 'zhtw') {
            if (ststus === 'across') {
              this.fileListZhtwAcross = [] // 把本地图片缓存清空
            } else {
              this.fileListZhtwUpright = []
            }
          } else {
            if (ststus === 'across') {
              this.fileListEnAcross = [] // 把本地图片缓存清空
            } else {
              this.fileListEnUpright = []
            }
          }
          return
        }
        if (type === 'zhcn') {
          if (ststus === 'across') {
            this.form.resourceNameHorZhcn = data.resourceName
            this.form.resourceUrlHorZhcn = data.resourceUrl
            // 压缩过长的名字
            this.form.resourceNameHorZhcn = this.$utils.cutFront(this.form.resourceNameHorZhcn)
          } else {
            this.form.resourceNameVerZhcn = data.resourceName
            this.form.resourceUrlVerZhcn = data.resourceUrl
            this.form.resourceNameVerZhcn = this.$utils.cutFront(this.form.resourceNameVerZhcn)
          }
        } else if (type === 'zhtw') {
          if (ststus === 'across') {
            this.form.resourceNameHorZhtw = data.resourceName
            this.form.resourceUrlHorZhtw = data.resourceUrl
            this.form.resourceNameHorZhtw = this.$utils.cutFront(this.form.resourceNameHorZhtw)
          } else {
            this.form.resourceNameVerZhtw = data.resourceName
            this.form.resourceUrlVerZhtw = data.resourceUrl
            this.form.resourceNameVerZhtw = this.$utils.cutFront(this.form.resourceNameVerZhtw)
          }
        } else {
          if (ststus === 'across') {
            this.form.resourceNameHorEn = data.resourceName
            this.form.resourceUrlHorEn = data.resourceUrl
            this.form.resourceNameHorEn = this.$utils.cutFront(this.form.resourceNameHorEn)
          } else {
            this.form.resourceNameVerEn = data.resourceName
            this.form.resourceUrlVerEn = data.resourceUrl
            this.form.resourceNameVerEn = this.$utils.cutFront(this.form.resourceNameVerEn)
          }
        }
        this.form.type = info.file.type
      }
    },
    // 轮播图新增弹窗
    addViewpager() {
      this.form = this.$options.data().form // 清空数据缓存
      this.fileListAcross = [] // 把本地图片缓存清空
      this.fileListUpright = []
      this.fileListZhtwAcross = []
      this.fileListZhtwUpright = []
      this.fileListEnAcross = []
      this.fileListEnUpright = []
      this.isShowAddViewpager = true
    },
    // 添加轮播图结束------------------------
    // 新增和详情弹跳框中的确认按钮功能------------
    async addViewpagerYes() {
      // 传入的id值为空实现增加功能的确认按钮事件，
      // 传入id不为空实现详情修改功能的确认按钮事件
      await this.$store.dispatch('viewpagerManage/viewpagerManageSave', {
        id: this.form.id,
        name: this.form.name,
        resourceNameHorEn: this.form.resourceNameHorEn,
        resourceNameHorZhcn: this.form.resourceNameHorZhcn,
        resourceNameHorZhtw: this.form.resourceNameHorZhtw,
        resourceNameVerEn: this.form.resourceNameVerEn,
        resourceNameVerZhcn: this.form.resourceNameVerZhcn,
        resourceNameVerZhtw: this.form.resourceNameVerZhtw,
        resourceUrlHorEn: this.form.resourceUrlHorEn,
        resourceUrlHorZhcn: this.form.resourceUrlHorZhcn,
        resourceUrlHorZhtw: this.form.resourceUrlHorZhtw,
        resourceUrlVerEn: this.form.resourceUrlVerEn,
        resourceUrlVerZhcn: this.form.resourceUrlVerZhcn,
        resourceUrlVerZhtw: this.form.resourceUrlVerZhtw,
        type: this.form.type
      })
      this.isSaveDetailData = true // 判断数据是否保存
      this.showList(this.newPage)
      this.isShowAddViewpager = false
    },
    // 新增和详情弹跳框中的确认按钮功能结束------------

    viewpagerCancel() { // 新增和详情弹窗取消触发该事件
      this.isSaveDetailData = false // 判断数据是否保存
    },
    // 轮播时间间隔设置------------------------
    async addViewpagerTime() { // 轮播图间隔时间的获取
      try {
        const data = await this.$store.dispatch('viewpagerManage/viewpagerGetTime', {
          standTime: 3
        })
        this.standTime = data.standTime.toString()
        this.addbuttomTime = true
      } catch (e) {
        this.addbuttomTime = true
      }
      this.addbuttomTime = true
    },
    // 轮播图间隔时间的更新
    async addViewpageTimerYes() {
      await this.$store.dispatch('viewpagerManage/viewpagerTime', {
        standTime: this.standTime
      })
      this.addbuttomTime = false
    },
    // 轮播时间间隔设置结束------------------------

    // 顺序调整
    async viewpagerManageSort(id, type) {
      await this.$store.dispatch('viewpagerManage/viewpagerManageSort', {
        id: id,
        type: type
      })
      this.showList(this.newPage)
    },

    // 轮播图详情------------------
    async viewpageDetail(list) {
      if (this.isSaveDetailData) { // 展示详情初始数据
        this.detailDatas = JSON.parse(JSON.stringify(list))
      } else { // 展示成功修改后的数据
        list = JSON.parse(JSON.stringify(this.detailDatas))
      }
      this.fileListAcross = [] // 把本地图片缓存清空
      this.fileListUpright = []
      this.fileListZhtwAcross = []
      this.fileListZhtwUpright = []
      this.fileListEnAcross = []
      this.fileListEnUpright = []
      this.form = list
      // console.log(this.form)
      this.form.resourceNameHorEn = this.$utils.cutFront(this.form.resourceNameHorEn)
      this.form.resourceNameHorZhcn = this.$utils.cutFront(this.form.resourceNameHorZhcn)
      this.form.resourceNameHorZhtw = this.$utils.cutFront(this.form.resourceNameHorZhtw)
      this.form.resourceNameVerEn = this.$utils.cutFront(this.form.resourceNameVerEn)
      this.form.resourceNameVerZhcn = this.$utils.cutFront(this.form.resourceNameVerZhcn)
      this.form.resourceNameVerZhtw = this.$utils.cutFront(this.form.resourceNameVerZhtw)
      this.isShowAddViewpager = true
    },
    // 获取后端数据库图片数据的存放路径
    downLoadImg(url) {
      this.$store.dispatch('download', { url })
    },
    // 轮播图详情结束------------------

    // 删除
    viewpageRemove(scope) {
      this.$confirm({
        title: `是否删除${scope.name}`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          await this.$store.dispatch('viewpagerManage/viewpagerManageRemove', {
            id: scope.id
          })
          this.showList(1)
        }
      })
    }
  },

}
</script>

<style lang='scss'>
@import "@/assets/styles/aiPuzzleViewpager.scss";
 /* 新增按钮  */
.c-viewpager-manage_add-buttom-bag{
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
}
/* 新增按钮结束  */
/* 轮播时间弹窗  */
.c-viewpager-manage_viewpager-time-bag{
    width: 300px;
    height: 30px;
    display: flex;
    // margin-bottom: 20px;
    .c-viewpager-manage_viewpager-time-bag-text{
        width: 100px;
        height: 30px;
        line-height: 30px;
        text-align: center;
    }
    .c-viewpager-manage_viewpager-time-bag-input{
         width: 100px;
        height: 30px;
    }
}
/* 轮播时间弹窗结束  */
//顺序上下按钮
.c-viewpager-manage_viewpager-bag-texts{
    width: 370px;
    height: 30px;
    padding-left: 19px;
    margin-bottom: 5px;
}
.c-viewpager-manage_mobile-buttom{
  margin-left: 5px;
}
</style>
